@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:regular,500,700,700&display=swap");

html, body {
    background-color: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

body > * {
    flex-shrink: 0;
}

body {
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-weight: normal;
    color: whitesmoke;
    margin: 0;
    overflow-x: hidden;
    overflow-y: visible;
    cursor: default;
    user-select: none;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #3445b4;
}

a:hover, a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

button {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

button:hover, button:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    line-height: 1.5;
    font-weight: 400;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    color: #000;
}

.ftco-section {
    padding: 7em 0;
}

.ftco-no-pt {
    padding-top: 0;
}

.ftco-no-pb {
    padding-bottom: 0;
}

.heading-section {
    font-size: 28px;
    color: #000;
}

.heading-section small {
    font-size: 18px;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.wrapper {
    width: 100%;
}

#sidebar {
    min-width: 270px;
    max-width: 270px;
    background: #0F212E;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

#sidebar .h6 {
    color: #fff;
}

#sidebar.active {
    margin-left: -270px;
}

#sidebar h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
}

#sidebar h1 .logo {
    color: #fff;
}

#sidebar h1 .logo span {
    font-size: 14px;
    color: #44bef1;
    display: block;
}

#sidebar ul.components {
    padding: 0;
}

#sidebar ul li {
    font-size: 16px;
}

#sidebar ul li > ul {
    margin-left: 10px;
}

#sidebar ul li > ul li {
    font-size: 14px;
}

#sidebar ul li a {
    padding: 10px 0;
    display: block;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a span {
    color: #44bef1;
}

#sidebar ul li a:hover {
    color: #fff;
}

#sidebar ul li.active > a {
    background: transparent;
    color: #fff;
}

@media (max-width: 991.98px) {
    #sidebar {
        margin-left: -270px;
    }

    #sidebar.active {
        margin-left: 0;
    }
}

#sidebar .custom-menu {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 0;
    margin-right: -20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar .custom-menu .btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
}

#sidebar .custom-menu .btn i {
    margin-right: -40px;
    font-size: 14px;
}

#sidebar .custom-menu .btn.btn-primary {
    background: transparent;
    border-color: transparent;
}

#sidebar .custom-menu .btn.btn-primary:after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #0F212E;
    border-radius: 0.626rem;
}

#sidebar .custom-menu .btn.btn-primary:hover, #sidebar .custom-menu .btn.btn-primary:focus {
    background: transparent !important;
    border-color: transparent !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    #sidebarCollapse span {
        display: none;
    }
}

#content {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.btn.btn-primary {
    background: #3445b4;
    border-color: #3445b4;
}

.btn.btn-primary:hover, .btn.btn-primary:focus {
    background: #3445b4 !important;
    border-color: #3445b4 !important;
}

.footer p {
    color: rgba(255, 255, 255, 0.5);
}

.form-control:focus, .form-control:active {
    border-color: #000;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
}

.subscribe-form .form-control {
    background: #3b4ec6;
}

.my-spinner {
    animation: spinner-border 0.85s linear infinite;
    font-size: x-large;
}

/*history list*/
.history-list-crash {
    overflow: hidden;
    width: 100%;
}

.history-list-crash a:hover {
    /*color: #FFF !important;*/
}

.history-list-crash ul {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

.history-list-crash li {
    font-size: 0.8rem;
    padding: 1px;
    display: flex;
    flex: 1 1;
    justify-content: center;
    text-align: center;
    margin: 0 1px;
    -webkit-transition: all 120ms ease-in;
    transition: all 120ms ease-in;
    font-weight: 700;

    border-radius: 15px;
    background-image: linear-gradient(206deg, #17181B, #2D2D2D) !important;
    box-shadow: 1px 1px 1px 1px #2D2D2D;

    height: 17px;
    line-height: 17px;
}

.history-list-crash li:hover {
    /*background: #19692c !important;*/
    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.chatsDan {
    font-size: .65rem !important;
}

.btn-send-chat {
    color: #FFF;
    border: 1px solid whitesmoke;
    border-radius: 0px 0.626rem 0.626rem 0px;
}

.btn-send-chat:hover {
    color: #e78f8f;
    border: 1px solid whitesmoke;
    border-radius: 0px 0.626rem 0.626rem 0px;
}

.thumb-xxs {
    height: 24px !important;
    width: 24px !important;
    font-size: 10px;
    font-weight: 700;
}

/* New UI -- Manual Bet */
.btn-amounts {
    border-radius: 0.626rem !important;
    background-color: #2D2D2D !important;
    padding: 0px !important;
    width: 45px !important;
    margin: 1px;
    box-shadow: 3px 3px 0.626rem #020b1a80, inset -1px -1px #2D2D2D, inset 1px 1px #fff1cd33 !important;;
    border: 1px solid rgba(0, 0, 0, .9) !important;;
}

#spinner {
    width: 4.8em;
    background-color: #000000;
    border: 2px solid #2D2D2D;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0px
}

#spinner:hover {
    box-shadow: none !important;
}

#spinner:disabled {
    color: grey;
}

.bet-amounts {
    background-color: #23262B;
    border-radius: 0.511rem;

    text-shadow: 0 1px 0 rgb(0 0 0 / 50%) !important;
    box-shadow: 2px 2px 3px #23262B, inset -1px -1px #2D2D2D, inset 1px 1px #fff1cd33 !important;;
    border: 1px solid rgba(0, 0, 0, .9) !important;
}

.sub-add-amount {
    box-shadow: 3px 3px 0.511rem #020b1a80, inset -1px -1px #182E49, inset 1px 1px #fff1cd33 !important;
    border: 1px solid rgba(0, 0, 0, .9) !important;
    border-radius: 50%;
    padding: 1px;
    width: 25px !important;
    height: 25px !important;
}

.btn-betting {
    width: 100%;
    height: 100%;
    border-radius: 0.626rem !important;
    background-color: rgb(67, 179, 9);

    text-shadow: 0 1px 0 rgb(0 0 0 / 50%) !important;
    box-shadow: 2px 2px 3px #23262B, inset -1px -1px #2D2D2D, inset 1px 1px #fff1cd33 !important;;
    border: 1px solid rgba(0, 0, 0, .9) !important;;
}

.btn-betting-default {
    color: white !important;
    background-image: conic-gradient(from 1turn,#43b309,#5ddb1c);
}

.btn-betting-wait {
    color: white !important;
    background-image: conic-gradient(from 1turn,#43b309, #56a829);
}

.btn-betting-cancel {
    color: whitesmoke !important;
    background-image: conic-gradient(from 1turn,#43b309, #3c8a14);
}

.btn-betting-cashout {
    color: white !important;
    background-image: conic-gradient(from 1turn,#FA821A, #ffa25e);
}

.bet-area {
    display: inline-flex;
    align-items: center;
    border-radius: 0.626rem;
    background-color: #2D2D2D;
    padding-left: 3px;
    padding-right: 3px;
}

.custom-card {
    display: inline-flex;
    align-items: center;
    border-radius: 0.626rem;
    background: #2D2D2D !important;
    padding: 4px 8px 5px 8px;
}

.custom-input {
    background-color: #17181B !important;
    border: 1px solid black;
    color: white !important;
    font-size: 1.2rem;
    padding: 0px 7px;
}

.custom-label {
    width: 7rem;
}

.custom-button {
    background-image: conic-gradient(from 1turn,#43b309,#5ddb1c);
    border-radius: 0.626rem !important;
}

.my-table-cell {
    padding: 3px 1px 3px 1px !important;
    font-size: .7rem !important;
}

.my-gradient {
    background: linear-gradient(180deg, #2D2D2D, rgb(0 0 0 / 0%));
    border-radius: 0.626rem;
    margin-bottom: 0.1rem;
    padding: 5px 5px;
    margin-right: 3px;
}

.btn-custom {
    color: black;
    background: #2D2D2D !important;
    border-radius: 0.626rem;
    border: 0;
}


/* Tabs */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    width: 90%;
    margin: 0 auto .2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid .12em #2D2D2D;
    border-radius: 0.626rem;
    padding-left: 0px;
    background-color: #2D2D2D;
}

.react-tabs__tab {
    width: 50%;
    padding: 0.1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.9s;
    border-radius: 0.626rem;
    box-shadow: none;
}

.react-tabs__tab--selected {
    background: #17181B;
    box-shadow: none;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:hover {
    background: #17181B;
    box-shadow: none;
}

.react-tabs__tab-panel {
    display: none;
    min-height: 211px;
}

.react-tabs__tab-panel--selected {
    display: block;
    /*text-align: center;*/
}

/*End Tabs*/

/*colors*/
.custom-panel-light {
    background-color: #2D2D2D !important;
}

.custom-panel-dark {
    background-color: #17181B !important;
}

.custom-panel-yellow {
    background-color: #FA821A !important;
}

.text-custom-yellow {
    color: #FA821A !important;
}

.game-table thead tr th {
    background-color: #0F212E;
}

.my_bets thead tr th {
    background-color: #0F212E;
}

.pc-link a {
    color: #ffffff;
}

/*Graph area*/
.game-aria {
    background: #17181B !important;
}

/*END Graph area*/

.custom-modal-content {
    background-color: #17181B;
    color: white;
    padding: 1.5px;
    border-radius: 0.626rem;
}

/*top banner*/
.top-count-down {
    background-image: conic-gradient(from 270deg, #FA821A, #2D2D2D);
    border-radius: 0.3rem;
    color: white;
    width: 20px;
    text-align: center;
    margin: 1px;
}

.btn-close {
    background-color: #5ddb1c;
    margin: 0px !important;
}

.mini-coin-9 {
    width: 15px;
    margin-bottom: 2px;
}